var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResizeHandler,
          expression: "onResizeHandler",
        },
      ],
      staticClass: "pa-1 product-view",
      style: "height: " + (_vm.windowSize.y + 70) + "px; position: 'relative'",
      attrs: { flat: "" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "primary",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            {
              ref: "pickForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pl-3 font-weight-bold",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("message.ccsheet.warehouse")) +
                          ": " +
                          _vm._s(_vm.sheetModel.warehousename) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pr-0 pb-0 mt-1",
                      attrs: { cols: "7" },
                    },
                    [
                      _c("barcode-scanner", {
                        attrs: {
                          search: _vm.scannedLocation,
                          scannerPayload: _vm.locationScannerPayload,
                          loading: _vm.loadingLocation,
                          disabled: _vm.lockLocation,
                        },
                        model: {
                          value: _vm.scannedLocation,
                          callback: function ($$v) {
                            _vm.scannedLocation = $$v
                          },
                          expression: "scannedLocation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pr-3 mt-2 text-center",
                      attrs: { cols: "5" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            color: "primary",
                            tabindex: "-1",
                          },
                          on: { click: _vm.scanNextLocation },
                        },
                        [_vm._v(_vm._s(_vm.$t("message.ccsheet.nextLoc")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0 mt-1", attrs: { cols: "12" } },
                    [
                      _c("barcode-scanner", {
                        attrs: {
                          search: _vm.scanProduct,
                          scannerPayload: _vm.productScannerPayload,
                          loading: _vm.loadingProduct,
                        },
                        model: {
                          value: _vm.scanProduct,
                          callback: function ($$v) {
                            _vm.scanProduct = $$v
                          },
                          expression: "scanProduct",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.productFound
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-1 pb-1 font-weight-bold",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("message.order.products")) +
                              ": " +
                              _vm._s(_vm.productInfo.productname || "") +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.productFound && _vm.productInfo.producthasbatch
                    ? [
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-0 pr-0 pb-0",
                            attrs: { cols: "8" },
                          },
                          [
                            _c("v-text-field", {
                              ref: "batchRef",
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                flat: "",
                                autocomplete: "off",
                                loading: _vm.loadingBatch,
                                rules: _vm.$_requiredValidation,
                                placeholder: _vm.$t("message.product.batch"),
                              },
                              on: { change: _vm.checkForValidBatchNumber },
                              model: {
                                value: _vm.selectBatch,
                                callback: function ($$v) {
                                  _vm.selectBatch = $$v
                                },
                                expression: "selectBatch",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.productFound || this.showAddCount
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "9" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center pa-0",
                                  attrs: { cols: "3" },
                                  on: { click: _vm.decreaseQty },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "info",
                                        fab: "",
                                        "x-small": "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-minus")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    ref: "qtyRef",
                                    staticClass: "text-right",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      type: "number",
                                      autocomplete: "off",
                                      rules: _vm.pickQty
                                        ? _vm.$_zeroValidation
                                        : [],
                                      placeholder: _vm.$t(
                                        "message.product.qty"
                                      ),
                                    },
                                    model: {
                                      value: _vm.pickQty,
                                      callback: function ($$v) {
                                        _vm.pickQty = $$v
                                      },
                                      expression: "pickQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center pa-0",
                                  attrs: { cols: "3" },
                                  on: { click: _vm.increaseQty },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "info",
                                        fab: "",
                                        "x-small": "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-1 mt-1", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                dark: "",
                                small: "",
                                loading: _vm.saveLoading,
                              },
                              on: { click: _vm.saveCountedProduct },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("message.ccsheet.count")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "highlight pt-0 pb-0 pl-1 mt-2" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 font-weight-bold",
                  attrs: { cols: "4" },
                },
                [_vm._v(_vm._s(_vm.$t("message.whsDashboard.product")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-center font-weight-bold",
                  attrs: { cols: "3" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.location")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-right font-weight-bold",
                  attrs: { cols: "1" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.unit")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.stock")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-right font-weight-bold smaller-font",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.counted")))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "pa-0" })],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.listOfProducts, function (product, index) {
            return [
              _c(
                "v-row",
                { key: index + "_stock" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pl-3 text-truncate bigger-font",
                      class: {
                        "pb-1": index === _vm.listOfProducts.length - 1,
                      },
                      attrs: { cols: "4", title: product.productnumber },
                    },
                    [_vm._v(" " + _vm._s(product.productnumber) + " ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 text-center text-truncate",
                      attrs: { cols: "3", title: product.locationname },
                    },
                    [_vm._v(_vm._s(product.locationname))]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 text-right text-truncate smaller-font",
                      attrs: { cols: "1", title: product.productunit },
                    },
                    [_vm._v(_vm._s(product.unit))]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 text-right text-truncate",
                      attrs: { cols: "2", title: product.onstock },
                    },
                    [_vm._v(_vm._s(_vm._f("absoluteNumber")(product.onstock)))]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pl-1 text-right text-truncate",
                      attrs: { cols: "2", title: product.counted },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("absoluteNumber")(product.counted)) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { key: index + "_product" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pl-4 text-truncate smaller-font",
                      attrs: { cols: "10" },
                    },
                    [_vm._v(_vm._s(product.productname))]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 text-right text-truncate",
                      attrs: { cols: "2" },
                    },
                    [_vm._v(_vm._s(product.batchnumber))]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-0 pt-1", attrs: { cols: "12" } },
                    [_c("v-divider", { staticClass: "pa-0" })],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _c("v-pagination", {
        attrs: { length: _vm.totalLength, circle: "", "total-visible": 5 },
        model: {
          value: _vm.activePage,
          callback: function ($$v) {
            _vm.activePage = $$v
          },
          expression: "activePage",
        },
      }),
      _c(
        "v-fab-transition",
        [
          _vm.can_complete == 1
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-2 complete_count_btn",
                  attrs: {
                    bottom: "",
                    left: "",
                    color: "primary complete_count_btn",
                    id: "scroll__top",
                    tabindex: "-1",
                    fixed: "",
                  },
                  on: { click: _vm.completeCountingHandler },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("message.ccsheet.closeComplete")) + " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "" },
          model: {
            value: _vm.showWarning,
            callback: function ($$v) {
              _vm.showWarning = $$v
            },
            expression: "showWarning",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-2" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("message.ccsheet.info")) + " "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        icon: "",
                        id: "warningClose",
                        fab: "",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showWarning = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-2" }, [
                _c("h4", [_vm._v(_vm._s(_vm.$t("message.ccsheet.stockTake")))]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "info",
                        small: "",
                        id: "continueCounting",
                      },
                      on: { click: _vm.continueCoutingHandler },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.ccsheet.continueCount")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        small: "",
                        id: "clearCounting",
                      },
                      on: { click: _vm.clearAndRecountHandler },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.ccsheet.clearAndRecount")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "" },
          model: {
            value: _vm.completeDialog,
            callback: function ($$v) {
              _vm.completeDialog = $$v
            },
            expression: "completeDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-2" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("message.ccsheet.info")) + " "),
                  _c("v-spacer"),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-2" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("message.ccsheet.ccsheetComplete"))),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "info",
                        small: "",
                        loading: _vm.completeLoading,
                      },
                      on: { click: _vm.completeSheetItems },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.completeDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "" },
          model: {
            value: _vm.addProductConfirmDialog,
            callback: function ($$v) {
              _vm.addProductConfirmDialog = $$v
            },
            expression: "addProductConfirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-2" },
                [
                  _vm._v(" " + _vm._s(_vm.$t("message.ccsheet.info")) + " "),
                  _c("v-spacer"),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-2" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("message.ccsheet.ccsheetAddProduct"))),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "info",
                        small: "",
                        loading: _vm.completeLoading,
                      },
                      on: { click: _vm.proceedWithProduct },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", small: "" },
                      on: {
                        click: function ($event) {
                          ;(_vm.addProductConfirmDialog = false),
                            (_vm.foundProduct = {})
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }